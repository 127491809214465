//import { posix } from "path";
import { useAppContext } from '../AppContext';
import { useLocation } from 'react-router-dom';
// import { motion, AnimatePresence } from 'framer-motion';


const DiscoveryLogoComponent: React.FC = () => {
    const location = useLocation();

    const { isAnnivContents } = useAppContext();


    // ページの背景色によってカンパニーロゴの画像を変更
    const getDiscoveryLogoUri = () => {
        if (location.pathname.includes('/value/') || location.pathname.includes('/menu/')) {
            return (
                `${process.env.PUBLIC_URL}/images/logo--discovery.svg`
            )
        } else {
            return (
                `${process.env.PUBLIC_URL}/images/logo--discovery_white.svg`
            )
        }
    };

    const getDiscoveryLogoWrapClassName = (): string => {
        if (location.pathname === '/') {
            return (
                'discoveryLogoWrap discoveryLogoWrap--home'
            )
        } else {
            return (
                'discoveryLogoWrap discoveryLogoWrap--pages'
            )
        }
    };

    const getDiscoveryLogoImgClassName = (): string => {
        if (location.pathname === '/') {
            return (
                'discoveryLogo discoveryLogo--home'
            )
        } else {
            return (
                'discoveryLogo discoveryLogo--pages'
            )
        }
    };

    const getDiscoveryLogoAnnivTextClassName = (): string => {
        if (location.pathname === '/') {
            return (
                'discoveryLogoAnnivText discoveryLogoAnnivText--home'
            )
        } else {
            return (
                'discoveryLogoAnnivText discoveryLogoAnnivText--pages'
            )
        }
    };



    let discoveryLogoUri = getDiscoveryLogoUri();


    return (
        <>

            <a className={getDiscoveryLogoWrapClassName()} href="https://discovery-inc.com" target="_blank" rel="noreferrer noopener">
                <img className={getDiscoveryLogoImgClassName()} src={discoveryLogoUri} alt="株式会社ディスカバリー" />
                {location.pathname !== '/' && isAnnivContents && (<span className={getDiscoveryLogoAnnivTextClassName()}>10th Anniv. Special Contents</span>)}
            </a>

        </>
    )
}

export default DiscoveryLogoComponent;