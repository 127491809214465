// CommentComponent.tsx
import React, { useState } from 'react';
import { useAppContext } from '../AppContext';
import { Link, useParams, useLocation } from 'react-router-dom';
//import { getAuth } from "firebase/auth";
//import { addDoc, collection, Timestamp } from "firebase/firestore";
//import { db } from '../DB/firebase';
//import { Comment } from './Utils/types';
import LikesComponent from './LikesComponent';
import { FaRegComment, FaCirclePlus } from "react-icons/fa6";
import { nl2br } from './Utils/helpers';


interface CommentComponentProps {
  category: string;
  sub_category: Record<string, any>;
}

const CommentComponent: React.FC<CommentComponentProps> = ({ category, sub_category = {} }) => {
  const location = useLocation();

  const {
    // isScreen,
    // setIsScreen,
    // isValueList,
    // setIsValueList,
    // isValueDetail,
    // setIsValueDetail,
    // isValueNum,
    // setIsValueNum,
    // isActionAndStance,
    // setIsActionAndStance,
    // isActionAndStanceNum,
    // setIsActionAndStanceNum,
    // isDepartment,
    // setIsDepartment,
    //isNavModalOpen,
    //setIsNavModalOpen,
    user,
    setUser,
    member,
    comments,
    setComments,
    isAnnivContents,
    isCommentFormOpen,
    setIsCommentFormOpen,
    commentCategoryInfo,
    setCommentCategoryInfo,
  } = useAppContext();

  const [visibleCount, setVisibleCount] = useState<number>(10);


  // const filteredCommentsByCategoryAndConditions = (category: string, conditions?: { key: string, val: any }[]) => {
  //   return comments?.filter(c => {
  //     if (c.category === category) {
  //       if (!conditions) {
  //         // 条件が指定されていない場合は、カテゴリのみでフィルタリングする
  //         return true;
  //       } else {
  //         // 各条件について、条件を満たすかどうかを確認する
  //         return conditions.every(({ key, val }) => {
  //           if (c.sub_category && key in c.sub_category) {
  //             // 特定のプロパティが存在し、その値が指定された条件と一致する場合に true を返す
  //             if (typeof c.sub_category[key] === typeof val) {
  //               return c.sub_category[key] === val;
  //             }
  //           }
  //           return false;
  //         });
  //       }
  //     }
  //     return false;
  //   });
  // };

  const filteredCommentsByCategoryAndConditions = (
    category: string,
    conditions?: { key: string, val: any }[]
  ) => {
    return comments?.filter((c) => {
      if (c.category === category) {

        // conditionsが指定されている場合（全てのcomment componentで設定必須　MissionComponent内→ <CommentComponent category='mission' sub_category={{}}/>）
        if (conditions) {
          const subCategoryKeys = Object.keys(c.sub_category);

          // 条件が指定されていない場合はカテゴリのみでフィルタリング（MISSIONの場合）
          if (subCategoryKeys.length === 0) {
            return true;
          }

          // sub_categoryのキーが"valueNum"のみの場合
          if (subCategoryKeys.length === 1 && subCategoryKeys[0] === 'valueNum') {
            return conditions.every(({ key, val }) => {
              return c.sub_category[key] === val;
            });
          }

          // sub_categoryが"valueNum"を含む複数のキーを持っている場合
          if (conditions.length > 1 && subCategoryKeys.includes('actionAndStanceNum')) {
            return conditions.every(({ key, val }) => {
              // 各条件がsub_categoryに存在するか、その値が条件に一致するか確認
              if (key in c.sub_category && typeof c.sub_category[key] === typeof val) {
                return c.sub_category[key] === val;
              }
              return false;
            });
          }
        }
      }
      return false;
    });
  };



  // 条件を格納する配列
  const conditions: { key: string, val: any }[] = [];

  // sub_category をマップして条件を生成
  Object.entries(sub_category).forEach(([key, val]) => {
    conditions.push({ key: key, val: val });
  });
  const commentsFilteredByCategoryAndConditions = filteredCommentsByCategoryAndConditions(category, conditions);


  const userComments = commentsFilteredByCategoryAndConditions?.filter(c => c.user_id === user?.uid);
  const otherComments = commentsFilteredByCategoryAndConditions?.filter(c => c.user_id !== user?.uid);

  if (!isAnnivContents) {
    return;
  };



  const getCommentsTitleText = () => {
    if (location.pathname === '/mission/') {
      return (
        'MISSIONへのコメント'
      )
    } else {
      if (location.pathname.includes('/action_and_stance/')) {
        return (
          'このACTION & STANCEへのコメント'
        )
      } else {
        return (
          'このVALUEへのコメント'
        )
      }
    }
  }

  const handleCommentFormOpen = (catergory: string, sub_category: any) => {
    // .footer__commentFormの高さを取得し.footer__commentFormのstyle属性のbottomプロパティに-1*高さを設定後、style属性のbottomプロパティを0に設定
    const footerCommentForm = document.querySelector('.footer__commentForm');
    if (footerCommentForm) {
      const height = footerCommentForm.clientHeight;
      (footerCommentForm as HTMLElement).style.bottom = `-${height}px`;
      (footerCommentForm as HTMLElement).style.bottom = `-1px`;
    }

    setIsCommentFormOpen(true);
    setCommentCategoryInfo({ category: catergory, sub_category: sub_category })
  }

  // ボタンがクリックされたら次の10件を表示
  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };




  return (
    <>
      {user && (
        <div className="comments">
          <h2 className="comments__title">
            <FaRegComment className="comments__title__icon" />
            <span className="comments__title__text">{getCommentsTitleText()}</span>
          </h2>

          <button className="comments__formOpenBtn" onClick={() => handleCommentFormOpen(category, sub_category)}>コメントする</button>


          {userComments !== null && userComments?.length !== 0 && (
            <div className="comments__userCommentList">
              <h3 className="comments__userCommentList__title">あなたのコメント</h3>

              <ul className="comments__userCommentList__list">
                {userComments?.map((comment) => (
                  <li key={comment.id}>
                    <div className="comments__commentList__list__comment-wrap">
                      <FaRegComment className="comments__userCommentList__list__commentIcon" />
                      <span className="comments__userCommentList__list__comment" dangerouslySetInnerHTML={{ __html: nl2br(comment.comment) }} />
                    </div>
                    <div className="comments__commentList__list__likes-wrap">
                      <LikesComponent comment_id={comment.id} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}


          <div className="comments__commentList">
            <h3 className="comments__commentList__title">コメント一覧</h3>
            {otherComments?.length == 0 ? (// TODO　別ユーザーでログインして表示されない問題を検証
              <div className="comments__commentList__no_comment_count_message">コメントはまだありません</div>
            ) : (
              <ul className="comments__commentList__list">
                {otherComments?.slice(0, visibleCount).map((comment) => (
                  <li key={comment.id}>
                    <div className="comments__commentList__list__comment-wrap">
                      <FaRegComment className="comments__commentList__list__commentIcon" />
                      <span className="comments__userCommentList__list__comment" dangerouslySetInnerHTML={{ __html: nl2br(comment.comment) }} />
                    </div>
                    <div className="comments__commentList__list__likes-wrap">
                      <span className="comments__commentList__list__username">
                        {member?.find((m) => m.id === comment.user_id)?.username || 'Unknown User'}
                      </span>
                      <LikesComponent comment_id={comment.id} />
                    </div>
                  </li>
                ))}
                {/* コメントがまだ残っている場合のみボタンを表示 */}
                {otherComments && (
                  visibleCount < otherComments.length && (
                    <button className="comments__commentList__list__more-btn" onClick={handleLoadMore}><FaCirclePlus />次の10件を読み込む</button>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  )
};

export default CommentComponent;
