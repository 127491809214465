// AppContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { collection, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from './DB/firebase';
import { Member, Comment, Flag, Like, CommentCategoryInfo } from './components/Utils/types';
//import ValueComponent from './components/ValueComponent';

// コンテキストの型定義
interface AppState {
  // isScreen: string;
  // setIsScreen: React.Dispatch<React.SetStateAction<string>>;
  // isValueList: boolean;
  // setIsValueList: React.Dispatch<React.SetStateAction<boolean>>;
  // isValueDetail: boolean;
  // setIsValueDetail: React.Dispatch<React.SetStateAction<boolean>>;
  isValueNum: number;
  setIsValueNum: React.Dispatch<React.SetStateAction<number>>;
  isDepartment: string;
  setIsDepartment: React.Dispatch<React.SetStateAction<string>>;
  // isActionAndStance: boolean;
  // setIsActionAndStance: React.Dispatch<React.SetStateAction<boolean>>;
  isActionAndStanceNum: number;
  setIsActionAndStanceNum: React.Dispatch<React.SetStateAction<number>>;
  isNavModalOpen: boolean;
  setIsNavModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAnnivContents: boolean;
  setIsAnnivContents: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  member: Member[] | null;
  setMember: React.Dispatch<React.SetStateAction<Member[] | null>>;
  comments: Comment[] | null;
  setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>;
  flags: Flag[] | null;
  setFlags: React.Dispatch<React.SetStateAction<Flag[] | null>>;
  likes: Like[] | null;
  setLikes: React.Dispatch<React.SetStateAction<Like[] | null>>;
  pinnedElements: string[];
  setPinnedElements: React.Dispatch<React.SetStateAction<string[]>>;
  isAutoZoomMode: boolean;
  setIsAutoZoomMode: React.Dispatch<React.SetStateAction<boolean>>;
  isCommentFormOpen: boolean;
  setIsCommentFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userInputCommentFormText: string;
  setUserInputCommentFormText: React.Dispatch<React.SetStateAction<string>>;
  commentCategoryInfo: CommentCategoryInfo | null;
  setCommentCategoryInfo: React.Dispatch<React.SetStateAction<CommentCategoryInfo | null>>;
}

// 初期値
const initialState: AppState = {
  // isScreen: 'home',
  // setIsScreen: () => {},
  // isValueList: true,
  // setIsValueList: () => {},
  // isValueDetail: false,
  // setIsValueDetail: () => {},
  isValueNum: 0,
  setIsValueNum: () => { },
  isDepartment: 'company',
  setIsDepartment: () => { },
  // isActionAndStance: false,
  // setIsActionAndStance: () => {},
  isActionAndStanceNum: 0,
  setIsActionAndStanceNum: () => { },
  isNavModalOpen: false,
  setIsNavModalOpen: () => { },
  isAnnivContents: true, // 10th Anniv. contents
  setIsAnnivContents: () => { },
  user: null,
  setUser: () => { },
  member: null,
  setMember: () => { },
  comments: null,
  setComments: () => { },
  flags: null,
  setFlags: () => { },
  likes: null,
  setLikes: () => { },
  pinnedElements: [],
  setPinnedElements: () => { },
  isAutoZoomMode: true,
  setIsAutoZoomMode: () => { },
  isCommentFormOpen: false,
  setIsCommentFormOpen: () => { },
  userInputCommentFormText: '',
  setUserInputCommentFormText: () => { },
  commentCategoryInfo: null,
  setCommentCategoryInfo: () => { },
};


// コンテキストの作成
const AppContext = createContext<AppState>(initialState);

// プロバイダーコンポーネントの作成
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // const [isScreen, setIsScreen] = useState<string>(initialState.isScreen);
  // const [isValueList, setIsValueList] = useState<boolean>(true);
  // const [isValueDetail, setIsValueDetail] = useState<boolean>(false);
  const [isValueNum, setIsValueNum] = useState<number>(0);
  const [isDepartment, setIsDepartment] = useState<string>('company');
  // const [isActionAndStance, setIsActionAndStance] = useState<boolean>(false);
  const [isActionAndStanceNum, setIsActionAndStanceNum] = useState<number>(0);
  const [isNavModalOpen, setIsNavModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [member, setMember] = useState<Member[] | null>(null);
  const [comments, setComments] = useState<Comment[] | null>([]);
  const [flags, setFlags] = useState<Flag[] | null>([]);
  const [likes, setLikes] = useState<Like[] | null>([]);
  const [isAnnivContents, setIsAnnivContents] = useState<boolean>(true); // SNSコンテンツへ切り替え
  const [pinnedElements, setPinnedElements] = useState<string[]>(initialState.pinnedElements);
  const [isAutoZoomMode, setIsAutoZoomMode] = useState<boolean>(true);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState<boolean>(false);
  const [userInputCommentFormText, setUserInputCommentFormText] = useState<string>(initialState.userInputCommentFormText);
  const [commentCategoryInfo, setCommentCategoryInfo] = useState<CommentCategoryInfo | null>(null);


  // ユーザーを取得
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // メンバー（全ユーザー）を取得
  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(collection(db, 'users'), (querySnapshot) => {
      const member: Member[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          username: data.username
        } as Member;
      });
      setMember(member);
    }, (error) => {
      console.error('Error fetching member: ', error);
    });

    return () => unsubscribe();
  }, [user]);


  //comments
  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(collection(db, 'comments'), (querySnapshot) => {
      const commentsList: Comment[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          user_id: data.user_id,
          status: data.status,
          category: data.category,
          sub_category: data.sub_category,
          comment: data.comment,
          created: data.created, // Timestamp 型
        } as Comment;
      });

      // createdを降順でソート（Timestampをミリ秒に変換して比較）
      const sortedComments = commentsList.sort((a, b) => {
        return b.created.toMillis() - a.created.toMillis(); // ミリ秒単位で比較して降順
      });

      setComments(sortedComments);
    }, (error) => {
      console.error('Error fetching comments: ', error);
    });

    return () => unsubscribe();
  }, [user]);



  /* //　↑をforEachで書き換えた例
  useEffect(() => {
  const unsubscribe = onSnapshot(collection(db, 'comments'), (querySnapshot) => {
    const commentsList: Comment[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const comment: Comment = {
        id: doc.id,
        user_id: data.user_id,
        status: data.status,
        true: data.true,
        category: data.category,
        sub_category: data.sub_category,
        comment: data.comment,
        created: data.created,
      };
      commentsList.push(comment);
    });
    setComments(commentsList);
  }, (error) => {
    console.error('Error fetching comments: ', error);
  });

  return () => unsubscribe();
}, []);
  */

  //旗全体のオブジェクトデータを取得
  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(collection(db, 'flags'), (querySnapshot) => {
      const flagsList: Flag[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          user_id: data.user_id,
          status: data.status,
          category: data.category,
          sub_category: data.sub_category,
          created: data.created,
        } as Flag;
      });
      setFlags(flagsList);
    }, (error) => {
      console.error('Error fetching flags: ', error);
    });

    return () => unsubscribe();
  }, [user]);

  /* ↑　上記と同じことができるコード

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'flags'), (snapshot) => {
      const flagsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Flag));
      setFlags(flagsData);
    });

    return () => unsubscribe();
  }, []);
  
    このコードでは、flagsData に対して型を明示的に宣言していませんが、map の中で直接オブジェクトリテラルにスプレッド構文 ...doc.data() を使って、Flag 型のアサーションを行っています。

    型定義がない理由
    1.簡潔さと読みやすさ: 2番目のコードは、スプレッド構文を使って doc.data() の全てのフィールドを一度に取り込み、型アサーションを用いて Flag 型として扱っています。この方法は、フィールドが多い場合やフィールド名が固定されている場合にコードを簡潔に保つことができます。

    2.スプレッド構文の利用: スプレッド構文 ...doc.data() を使用することで、doc.data() の全てのプロパティを新しいオブジェクトにコピーしています。これにより、全てのフィールドを一つ一つ取り出す必要がなくなり、コードが簡潔になります。

    3.型アサーションの位置: flagsData に対する型アサーションを map の中で直接行っているため、flagsData 自体の型定義が省略されています。TypeScript はこの場合、setFlags の型から flagsData の型を推論することができます。

  */


  //いいね全体のオブジェクトデータを取得
  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onSnapshot(collection(db, 'likes'), (querySnapshot) => {
      const likesList: Like[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          user_id: data.user_id,
          status: data.status,
          comment_id: data.comment_id,
          created: data.created,
        } as Like;
      });
      setLikes(likesList);
    }, (error) => {
      console.error('Error fetching likes: ', error);
    });

    return () => unsubscribe();
  }, [user]);








  return (
    <AppContext.Provider
      value={{
        // isScreen,
        // setIsScreen,
        // isValueList,
        // setIsValueList,
        // isValueDetail,
        // setIsValueDetail,
        isValueNum,
        setIsValueNum,
        // isActionAndStance,
        // setIsActionAndStance,
        isActionAndStanceNum,
        setIsActionAndStanceNum,
        isDepartment,
        setIsDepartment,
        isNavModalOpen,
        setIsNavModalOpen,
        isAnnivContents,
        setIsAnnivContents,
        user,
        setUser,
        member,
        setMember,
        comments,
        setComments,
        flags,
        setFlags,
        likes,
        setLikes,
        pinnedElements,
        setPinnedElements,
        isAutoZoomMode,
        setIsAutoZoomMode,
        isCommentFormOpen,
        setIsCommentFormOpen,
        userInputCommentFormText,
        setUserInputCommentFormText,
        commentCategoryInfo,
        setCommentCategoryInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// フックを作成して、コンテキストを使用する
export const useAppContext = () => useContext(AppContext);
