import React, { useState, useEffect } from 'react';
import { useAppContext } from './AppContext';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { Mission, mission } from './data/Mission';
import { Value, value } from './data/Value';
import { Departments, departments } from './data/Departments';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import NavigationComponent from './components/NavigationComponent';
import DiscoveryLogoComponent from './components/DiscoveryLogoComponent';
import HomeComponent from './components/HomeComponent';
import MenuComponent from './components/MenuComponent';
import UserMypageComponent from './components/UserMypageComponent';
import NotFoundComponent from './components/NotFoundComponent';
import MissionComponent from './components/MissionComponent';
import ValueComponent from './components/ValueComponent';
import ValueDetailComponent from './components/ValueDetailComponent';
import ActionAndStanceComponent from './components/ActionAndStanceComponent';


// グローバルスコープで Window インターフェースを拡張
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}


const App = () => {

  const location = useLocation();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-KLPFVMZ7', //for SNS https://discover-your-flags.discovery-inc.com/
      // gtmId: 'GTM-5L89V7K', //for valuebook https://discovery-inc.com/valuebook/
    };
    TagManager.initialize(tagManagerArgs);
  }, []);



  useEffect(() => {
    // GTM の dataLayer にページビューイベントを送信
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page_path: location.pathname + location.search,
    });
  }, [location]);





  //const { isScreen, setIsScreen, isValueList, setIsValueList, isValueDetail, setIsValueDetail, isValueNum, setIsValueNum,isActionAndStance,setIsActionAndStance,isActionAndStanceNum,setIsActionAndStanceNum, isDepartment, setIsDepartment,user,setUser,isAnnivContents } = useAppContext();
  const { isAnnivContents, isAutoZoomMode } = useAppContext();

  return (
    <div className={`App ${isAnnivContents ? 'isAnnivContents' : ''} ${isAutoZoomMode ? 'isAutoZoomMode' : ''}`}>
      <HeaderComponent />
      <AnimatePresence> {/* AnimatePresence直下の要素には一意なkeyの付与が必要（errorが出る） */}
        <main className="main" key="main">
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/menu/" element={<MenuComponent value={value} mission={mission} />} />
            <Route path="/mission/" element={<MissionComponent mission={mission} />} />
            <Route path="/value/" element={<ValueComponent value={value} />} />
            <Route path="/value/detail/:value_num" element={<ValueDetailComponent value={value} />} />
            <Route path="/value/detail/:value_num/action_and_stance/:department_name/" element={<ActionAndStanceComponent value={value} />} />
            {isAnnivContents && (
              <Route path="/mypage/" element={<UserMypageComponent />} />
            )}
            <Route path="*" element={<NotFoundComponent />} /> {/* 404ページ用のルート */}
          </Routes>
          <DiscoveryLogoComponent />
        </main>
        <NavigationComponent mission={mission} value={value} departments={departments} key="navigation" />
        <FooterComponent key="footer" />
      </AnimatePresence>
    </div>
  );
}

const AppWrapper = () => { // gtmのdataLayer変数をpushするために使用するuseLocationはBrowserRouter内でしか使用できないため、App全体をwrap 2024.10.07 nagatani
  return (
    <>
      {/* <BrowserRouter basename='/valuebook/'> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
};

export default AppWrapper;
